import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../../assets/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
// --------------- icons ------------------------------
import { HiHeart } from "react-icons/hi";
import CompRenderingContext from "../../../context/CompRenderingContext";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import GLobalStoreContext from "../../../context/GlobalStoreContext";
import { MdNotListedLocation } from "react-icons/md";
import useAddressManager from "../../../hooks/address/useAddressManager";
import Price from "./price/index";

export default function ProductCard({
  index,
  product,
  width,
  min_width,
  onProductClickCallBack,
  inPreviewCardCallBack,
}) {
  // ------------------------ hooks ------------------------------

  const navigate = useNavigate();
  const cardRef = React.useRef(null);
  const { hubId } = useAddressManager();

  const [inPreview, setInPreview] = useState(false);

  useEffect(() => {
    if (inPreview) {
      inPreviewCardCallBack && inPreviewCardCallBack(index, product);
    }

    // eslint-disable-next-line
  }, [inPreview]);

  useEffect(() => {
    // Copy the current ref value to a local variable
    const node = cardRef.current;
    if (!node) return; // Exit early if there's no node to observe

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update the state based on whether the node is intersecting
        setInPreview(entry.isIntersecting);
      },
      {
        threshold: 0.5,
        rootMargin: "-100px 0px 0px 0px",
      }
    );

    // Start observing the node
    observer.observe(node);

    // Cleanup function using the stored node
    return () => {
      observer.unobserve(node);
    };
  }, []);

  // -------------- contexts ------------------------------
  const { toggleWishlistItem, wishlistedArray } =
    useContext(GLobalStoreContext);
  const { setLocationData } = useContext(CompRenderingContext);

  const { user } = useContext(AuthContext);

  const [isWishlistingSpinner, setIsInWishlistSpinner] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(false);

  useEffect(() => {
    setIsInWishlist(false);
    wishlistedArray.forEach((curr) => {
      product?._id === curr && setIsInWishlist(true);
    });
    // eslint-disable-next-line
  }, [wishlistedArray]);

  // ------------------------------functions ------------------------------
  const onAddToWishlist = async () => {
    if (!user) {
      window.toast("Please login to wishlist");
      window.goToLogin();

      return;
    }
    setIsInWishlistSpinner(true);
    isInWishlist
      ? await toggleWishlistItem(product, "remove")
      : await toggleWishlistItem(product, "add");
    setTimeout(() => {
      setIsInWishlistSpinner(false);
    }, 500);
  };

  const onProductClick = () => {
    navigate(
      `/products/decor/${product?._id}/${product?.name
        .trim()
        .replace("&", "and")
        .split(" ")
        .join("-")}`
    );
    onProductClickCallBack && onProductClickCallBack();
  };

  if (!hubId) {
    return (
      <CardWhenNoLocation
        product={product}
        width={width}
        cardRef={cardRef}
        min_width={min_width}
        onProductClick={onProductClick}
        setLocationData={setLocationData}
      />
    );
  }

  return (
    <div
      style={{
        width: width,
        minWidth: min_width,
      }}
      ref={cardRef}
      className={`flex flex-col relative `}
    >
      <div
        onClick={() => {
          !isWishlistingSpinner && onAddToWishlist();
        }}
        className="flex rounded-[50%] text-[1rem]  absolute w-[1.3rem] h-[1.3rem] bg-white shadow-md right-1 top-1 items-center justify-center"
      >
        {isWishlistingSpinner ? (
          <div className="relative right-[.3rem] bottom-[.8rem]">
            <Spinner size={10} />
          </div>
        ) : isInWishlist ? (
          <HiHeart className={` text-[#2136d4] rounded-full`} />
        ) : (
          <HiHeart
            className={` text-[#b4b4b4ab] hover:scale-[1.2] hover:text-[#b5c0ffab] transition-[all] rounded-full  `}
          />
        )}
      </div>
      <LazyLoadImage
        src={product?.images[0]}
        alt={product?.name}
        className={`w-full aspect-square border border-[#e6e6e6] rounded-lg `}
        loading="lazy"
        width="100%"
        height="100%"
        title={product?.name}
        placeholderSrc="/img/placeholder.svg"
        onClick={() => {
          onProductClick();
        }}
      />

      <div
        onClick={() => {
          onProductClick();
        }}
        className="flex leading-5 tracking-[.6px] flex-col px-2 pt-1 pb-2"
      >
        <p className=" h-auto md:max-h-[2.5rem] max-h-[2.5rem] min-h-[2rem] overflow-hidden    md:text-[.875rem] text-[.8125rem] font-[500]  text-[#000000] ">
          {product?.name}
        </p>
        <Price
          {...{
            product,
            isProductCard: true,
          }}
        />
      </div>
    </div>
  );
}

const CardWhenNoLocation = ({
  product,
  width,
  min_width,
  setLocationData,
  onProductClick,
  cardRef,
}) => {
  return (
    <div
      style={{
        width: width,
        minWidth: min_width,
      }}
      className={`flex flex-col`}
      ref={cardRef}
    >
      <div className="flex flex-col w-full   overflow-hidden rounded-lg">
        <LazyLoadImage
          src={product?.images[0]}
          alt={product?.name}
          className={`w-full aspect-square border border-[#e6e6e6] rounded-lg `}
          loading="lazy"
          width="100%"
          height="100%"
          title={product?.name}
          placeholderSrc="/img/placeholder.svg"
          onClick={onProductClick}
        />

        <div className="flex leading-5 tracking-[.6px] flex-col mb-1 px-2">
          <p
            onClick={onProductClick}
            className=" h-auto md:max-h-[2.5rem] max-h-[2.5rem] min-h-[2rem] overflow-hidden    md:text-[.875rem] text-[.8rem] font-[500]  text-[#000000] "
          >
            {product?.name}
          </p>
        </div>
        <div
          onClick={() =>
            setLocationData((p) => ({
              ...p,
              isLocationAddModalRender: true,
            }))
          }
          className=" mirror-Animation before:w-[10px] bg-gradient-to-l from-blue-50 to-white justify-between rounded-r-full flex mirror-Animation bg-[white] mt-1 items-center pr-2 mx-2 mb-2 py-2 gap-1"
        >
          <div className="flex flex-col whitespace-nowrap ">
            <div className="text-[1rem] leading-4 text-[#2136d4] font-[500]">
              Set location
            </div>
            <div className="text-[.75rem] text-[grey] leading-[15px]">
              To view price
            </div>
          </div>
          <MdNotListedLocation className="text-[#2136d4] text-[1.8rem]" />
        </div>
      </div>
    </div>
  );
};
