import React from "react";
import { ResponsiveProvider } from "./context/ResponsiveContext";
import { AuthProvider } from "./context/AuthContext";
import { AddonProvider } from "./context/AddonContext";
import { OfferProvider } from "./context/OfferContext";
import { AddressProvider } from "./context/AddressContext";
import { OrderProvider } from "./context/OrderContext";
import { ProductPreviewProvider } from "./context/ProductPreviewContext";
import { SingleProductProvider } from "./context/SingleProductContext";
import MainRouter from "./routes/MainRouter";
import { CompRenderingProvider } from "./context/CompRenderingContext";
/* css part */
import { PaymentProvider } from "./context/PaymentContext";
import { HomePageProvider } from "./context/HomepageContext";
import { ScrollHistoryProvider } from "./context/ScrollHistoryContext";
import { GLobalStoreProvider } from "./context/GlobalStoreContext";
import ErrorPage from "./assets/ErrorPage";
import "./index.css";
import "./shinyball.css";
import "./App.css";
import "animate.css";
import { StoreProvider } from "./context/StoreContext";
import { ProductsListingProvider } from "./context/ProductsListingContext";
import { PreloadBoundary } from "./boundaries";
import { ErrorBoundary } from "react-error-boundary";

function App({ initialDataFromServer }) {
  return (
    <ScrollHistoryProvider>
      <StoreProvider>
        <ResponsiveProvider>
          <CompRenderingProvider>
            <GLobalStoreProvider>
              <AuthProvider>
                <PreloadBoundary>
                  <ErrorBoundary
                    fallbackRender={(e) => {
                      return <ErrorPage e={e} />;
                    }}
                  >
                    <HomePageProvider>
                      <AddonProvider>
                        <AddressProvider>
                          <OrderProvider>
                            <ProductsListingProvider>
                              <ProductPreviewProvider>
                                <OfferProvider>
                                  <SingleProductProvider>
                                    <PaymentProvider>
                                      <MainRouter
                                        initialDataFromServer={
                                          initialDataFromServer
                                        }
                                      />
                                    </PaymentProvider>
                                  </SingleProductProvider>
                                </OfferProvider>
                              </ProductPreviewProvider>
                            </ProductsListingProvider>
                          </OrderProvider>
                        </AddressProvider>
                      </AddonProvider>
                    </HomePageProvider>
                  </ErrorBoundary>
                </PreloadBoundary>
              </AuthProvider>
            </GLobalStoreProvider>
          </CompRenderingProvider>
        </ResponsiveProvider>
      </StoreProvider>
    </ScrollHistoryProvider>
  );
}

export default App;
