import axios from "axios";

export const v2Api = async (options) => {
  try {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v2/`,
      {
        options: { ...options, token: token },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error logging user location:", error);
  }
};

const preloadApi = async (options) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/preload`,
      {
        options: JSON.stringify(options),
      }
    );

    if (typeof res.data === "string") {
      return JSON.parse(res.data);
    }
    return res.data;
  } catch (error) {
    console.error("Error logging user location:", error);
  }
};
export { preloadApi };
