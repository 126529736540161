import React, { createContext, useContext, useEffect, useState } from "react";
import { get_product_by_query_object } from "../api/productApi";
import { useLocation, useNavigate } from "react-router-dom";
import { get_banners } from "../api/bannersApi";
import ResponsiveContext from "./ResponsiveContext";
import useAddressManager from "../hooks/address/useAddressManager";

const ProductPreviewContext = createContext();

export const ProductPreviewProvider = ({ children }) => {
  // ----------------- ** REACT ROUTER HOOKS ** ---------------
  const location = useLocation();
  const navigate = useNavigate();
  const { screenWidth } = useContext(ResponsiveContext);

  const { hubId } = useAddressManager();
  // -------------------** CONTEXTS ** ---------------------
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // ---------------------- ** STATES ** --------------------
  const [previewProducts, setPreviewProducts] = useState({
    previewProductData: [],
    isLoading: false,
    categoriesByTags: {
      tagsObjects: [],
      selectedTag: null,
    },
  });

  const [productListingpageBanners, setProductListingPageBanners] = useState({
    topbanner: "",
    inBetweenProductBanner1: "",
    inBetweenProductBanner2: "",
  });

  const [showFilter, setShowFilter] = useState(false);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [currPage, setCurrPage] = useState({
    page: 0,
    isLast: false,
  }); // for pagination purpose in products preview page
  const [currUserPos, setCurrUserPos] = useState(0);

  // --------------- ** USE EFFECTS ** ------------------

  useEffect(() => {
    const pattern1 = /product/;
    const pattern2 = /checkout/;
    if (
      !(pattern1.test(location.pathname) || pattern2.test(location.pathname))
    ) {
      resetPagePositions();
    }
    handleProductbannersFetch();
    // eslint-disable-next-line
  }, [location]);

  // ------------ ** FUNCTIONS **-----------------

  const handleProductbannersFetch = async () => {
    try {
      if (
        !decodeURIComponent(location.pathname)
          .trim()
          .includes(decodeURIComponent("/products/").trim()) &&
        !decodeURIComponent(location.pathname)
          .trim()
          .includes(decodeURIComponent("/decors/").trim())
      ) {
        return;
      }
      const res = await get_banners({
        queryObject: {
          placeType: "product_listing_page_banner",
          or: [{ device: "both" }, { device: screenWidth > 768 ? "lg" : "sm" }],
        },
        limit: 10,
      });
      if (res.isSuccess) {
        let topPreviewBanner = "";
        let inBetweenPreviewBanner1 = "";
        let inBetweenPreviewBanner2 = "";

        res.result.forEach((curr) => {
          if (parseInt(curr.placementPosition) === 1) {
            // working for top preview banner

            if (curr.showForParticularUrl.length === 0) {
              topPreviewBanner = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  topPreviewBanner = curr;
                }
              });
            }
          } else if (parseInt(curr.placementPosition) === 2) {
            // working for in between preview banner
            if (curr.showForParticularUrl.length === 0) {
              inBetweenPreviewBanner1 = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  inBetweenPreviewBanner1 = curr;
                }
              });
            }
          } else if (parseInt(curr.placementPosition) === 3) {
            // working for in between preview banner
            if (curr.showForParticularUrl.length === 0) {
              inBetweenPreviewBanner2 = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  inBetweenPreviewBanner2 = curr;
                }
              });
            }
          }
        });

        setProductListingPageBanners({
          topbanner: topPreviewBanner,
          inBetweenProductBanner1: inBetweenPreviewBanner1,
          inBetweenProductBanner2: inBetweenPreviewBanner2,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const resetPagePositions = async () => {
    setCurrPage({
      page: 0,
      isLast: false,
    });
    setCurrUserPos(0);
  };

  const getProductsByFilter = async ({ filter, page = currPage.page }) => {
    page === 0 && setPreviewProducts((prev) => ({ ...prev, isLoading: true }));

    if (page !== currPage.page) {
      setCurrPage({
        ...currPage,
        page: 0,
      });
    }

    // does the filter.tags contain categoriesByTags.selectedTag
    if (page !== 0 && previewProducts.categoriesByTags.selectedTag) {
      if (!filter.tags) {
        filter.tags = [];
      }
      if (!filter.tags.includes(previewProducts.categoriesByTags.selectedTag)) {
        filter.tags.push(previewProducts.categoriesByTags.selectedTag);
      }
    }

    const res = await get_product_by_query_object({
      BaseUrl: REACT_APP_BACKEND_URL,
      queryObject: {},
      skip: page * 54,
      limit: 54,
      filter: {
        ...filter,
        hubId: hubId,
        active: true,
        variantExclude: true,
      },
      tags: ["getProductsByFilter"],
    });

    setTotalProductsCount(res.productsCount);

    if (res.products.length <= 0 && page !== 0) {
      setCurrPage({
        ...currPage,
        isLast: true,
      });
      return;
    }
    let newProducts = [];
    if (page !== 0) {
      newProducts = previewProducts.previewProductData.map((curr) => curr);
    }
    res.products.forEach((element) => {
      newProducts.push(element);
    });

    setPreviewProducts((prev) => ({
      ...prev,
      previewProductData: newProducts,
      isLoading: false,
      categoriesByTags: {
        ...prev.categoriesByTags,
        tagsObjects: res.categoriesByTags,
      },
    }));
  };

  // ------------------> fetch products for and return the fetched products
  const fetchProductsByQuery = async (
    queryObject,
    skip,
    limit,
    sortingObj,
    projectionString,
    pageLocation
  ) => {
    const res = await get_product_by_query_object({
      BaseUrl: REACT_APP_BACKEND_URL,
      queryObject: { ...queryObject, active: true },
      skip,
      limit,
      sortingObj,
      hubId: hubId,
      projectionString,
    });

    if (res.products.length === 0 && pageLocation === "_SINGLE_PRODUCT_PAGE")
      navigate("/");

    return res.products;
  };

  const getBySearchText = async (searchText) => {
    try {
      const searchAggregationPipeline = [
        {
          $facet: {
            fullMatch: [
              {
                $match: {
                  $or: [
                    { name: { $regex: `^${searchText}$`, $options: "i" } },
                    { _id: { $regex: `^${searchText}$`, $options: "i" } },
                    { tags: { $regex: `^${searchText}$`, $options: "i" } },
                  ],
                  active: true,
                },
              },
              { $limit: 8 },
            ],
            inOrderMatch: [
              {
                $match: {
                  name: {
                    $regex: `.*${searchText.split(/\s+/).join(".*")}.*`,
                    $options: "i",
                  },

                  active: true,
                },
              },
              { $limit: 8 },
            ],
            anyOrderMatch: [
              {
                $match: {
                  $and: searchText.split(/\s+/).map((word) => ({
                    $or: [
                      { name: { $regex: word, $options: "i" } },
                      // { _id: { $regex: word, $options: "i" } },  will be used like this if required
                      // { tags: { $regex: word, $options: "i" } }
                    ],
                  })),
                  active: true,
                },
              },
              { $limit: 8 },
            ],
            atLeastOneWordMatch: [
              {
                $match: {
                  $or: searchText.split(/\s+/).map((word) => ({
                    $or: [{ name: { $regex: word, $options: "i" } }],
                  })),
                  active: true,
                },
              },
              { $limit: 8 },
            ],
          },
        },
        {
          $project: {
            finalResults: {
              $concatArrays: [
                { $ifNull: ["$fullMatch", []] },
                { $ifNull: ["$inOrderMatch", []] },
                { $ifNull: ["$anyOrderMatch", []] },
                { $ifNull: ["$atLeastOneWordMatch", []] },
              ],
            },
          },
        },
        { $unwind: "$finalResults" },
        { $replaceRoot: { newRoot: "$finalResults" } },
        {
          $group: {
            _id: "$_id",
            name: { $first: "$name" },
            images: { $first: "$images" },
            sellingPrice: { $first: "$sellingPrice" },
            costPrice: { $first: "$costPrice" },
            webDiscount: { $first: "$webDiscount" },
            tags: { $first: "$tags" },
            listingPriceHubWise: { $first: "$listingPriceHubWise" },
          },
        },
        // {
        //   $project: {
        //     _id: 1,
        //     name: 1,
        //     images: 1,
        //     sellingPrice: 1,
        //     costPrice: 1,
        //     webDiscount: 1,
        //     tags: 1,
        //     listingPriceHubWise: 1,
        //   },
        // },
        { $limit: 8 },
      ];
      const res = await get_product_by_query_object({
        BaseUrl: REACT_APP_BACKEND_URL,
        hubId: hubId,
        pipeline: searchAggregationPipeline,
      });
      return res.products;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ProductPreviewContext.Provider
      value={{
        getBySearchText,
        previewProducts,
        showFilter,
        setShowFilter,
        setPreviewProducts,
        currPage,
        setCurrPage,
        resetPagePositions,
        currUserPos,
        setCurrUserPos,
        totalProductsCount,
        setTotalProductsCount,
        fetchProductsByQuery,
        productListingpageBanners,
        getProductsByFilter,
      }}
    >
      {children}
    </ProductPreviewContext.Provider>
  );
};

export default ProductPreviewContext;
