import React, { createContext, useEffect, useState } from "react";
import { get_offers_by_query_object } from "../api/offerApi";

import useCartManager from "../hooks/cart/useCartManager";
import cartUtils from "../utils/cartUtils";
import useAddressManager from "../hooks/address/useAddressManager";
import { useStoreContext } from "./StoreContext";

const OfferContext = createContext();

export const OfferProvider = ({ children }) => {
  // -------------- ** context ** -------------
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const { cart, updateCart } = useCartManager();

  const { offerState, setOfferState } = useStoreContext();

  const { hubId } = useAddressManager();
  // -------------- ** states ** -------------

  const [availableOffers, setAvailableOffers] = useState([]);

  const [missingOutOffers, setMissingOutOffers] = useState([]);

  // ------------------ ** use effects ** -------------------
  useEffect(() => {
    cart?.product && offerFilteration();
    // eslint-disable-next-line
  }, [offerState, cart]);

  const onRemoveCoupon = async () => {
    updateCart([["offers", []]]);
  };

  const offerFilteration = () => {
    let availableOffersList = [];
    let notAvailableOffersList = [];

    for (const offer of offerState.offers) {
      const invalidation = cartUtils(cart).evaluateOfferMinimumValue(
        offer,
        cart,
        hubId
      );
      if (invalidation.invalid) {
        notAvailableOffersList.push(offer);
      } else {
        availableOffersList.push(offer);
      }
    }

    setAvailableOffers(availableOffersList);
    setMissingOutOffers(notAvailableOffersList);
  };

  const getOffers = async () => {
    setOfferState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const res = await get_offers_by_query_object({
        BaseUrl: REACT_APP_BACKEND_URL,
        queryObject: {},
        skip: 0,
        limit: 100,
        sortingObj: {},
      });
      if (res.isSuccess) {
        setOfferState({
          ...offerState,
          isLoading: false,
          offers: res.offers,
        });
      } else {
        setOfferState({
          ...offerState,
          isLoading: false,
        });
      }
    } catch (error) {
      console.error(error);
      setOfferState({
        ...offerState,
        isLoading: false,
      });
    }
    // eslint-disable-next-line
  };

  const offerValidationCheck = async (couponCode) => {
    if (couponCode.toUpperCase() === "")
      return {
        message: "Please enter a promo code",
        isSuccess: false,
        isInvalid: true,
      };

    let offerDetails = null;
    offerState.offers?.forEach((currOffer) => {
      if (couponCode.toUpperCase().trim() === currOffer.offerCode)
        offerDetails = currOffer;
    });

    if (!offerDetails) {
      return {
        message: `Promo code ${couponCode.toUpperCase()} is invalid. Please try another code `,
        isSuccess: false,
        isInvalid: true,
      };
    }

    const validation = cartUtils(cart).evaluateOfferMinimumValue(
      offerDetails,
      cart,
      hubId
    );

    if (validation.invalid) {
      return {
        message: ` Add worth ₹${validation.moneyNeededToGetOffer} more to get this offer`,
        isSuccess: false,
        isInvalid: true,
      };
    } else {
      updateCart([["offers", [offerDetails]]]);

      return {
        message: "Promo code applied successfully",
        isSuccess: true,
        isInvalid: false,
        offerDetails: offerDetails,
      };
    }
  };

  return (
    <OfferContext.Provider
      value={{
        offerState,
        getOffers,
        availableOffers,
        setAvailableOffers,
        missingOutOffers,
        setMissingOutOffers,
        onRemoveCoupon,
        offerValidationCheck,
        offerFilteration,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferContext;
