import cartUtils from "../utils/cartUtils";

const calc_least_price = (productPrice, offers) => {
  let max_gain = 0;
  let offerCode = "";
  offers?.forEach((offer) => {
    if (offer.visibility && offer.active) {
      const discountValue = cartUtils().calculateCouponDiscount(
        offer,
        productPrice
      );

      if (productPrice >= offer.minOrderValue && max_gain < discountValue) {
        offerCode = offer.offerCode;
        max_gain = discountValue;
      }
    }
  });

  let leastProductPrice = productPrice - max_gain;

  return {
    leastPrice: Math.round(leastProductPrice),
    offerCode,
    discount: max_gain,
  };
};

export { calc_least_price };
