import React, { useContext, memo, useRef, Fragment } from "react";
import ProductPreviewContext from "../../../../context/ProductPreviewContext";
import ProductCard from "../../../cards/product/index";
import ResponsiveContext from "../../../../context/ResponsiveContext";
import CustomBanner from "../../listings/c/CustomBanner";
import DownloadAppPoster2 from "../../../downloadApp/DownloadAppPoster2";

const KwpDecorList = ({ decors }) => {
  const productListCont = useRef(null);

  // -------------------- context --------------------
  const { productListingpageBanners } = useContext(ProductPreviewContext);

  const { screenWidth } = useContext(ResponsiveContext);

  return (
    <div
      ref={productListCont}
      style={{
        rowGap: ".875rem",
      }}
      className="flex w-[100%] scroll-bar-remove scroll-smooth flex-wrap  justify-evenly overflow-scroll  "
    >
      {decors.map((product, index) => (
        <Fragment key={`${product?._id}`}>
          <ProductCard
            onProductClickCallBack={null}
            product={product}
            width={screenWidth > 768 ? "22%" : "47%"}
            min_width={screenWidth > 768 ? "22%" : "47%"}
          />

          {index !== 0 && (index + 1) % (screenWidth > 768 ? 16 : 8) === 0 ? (
            <CustomBanner
              productListingpageBanners={productListingpageBanners}
              idx={index + 1}
            />
          ) : (
            index !== 0 &&
            (index + 1) % (screenWidth > 768 ? 8 : 4) === 0 && (
              <>
                {screenWidth > 768 ? (
                  <CustomBanner
                    productListingpageBanners={productListingpageBanners}
                    idx={index + 1}
                  />
                ) : (
                  <div className="w-full mx-2">
                    <DownloadAppPoster2 />
                  </div>
                )}
              </>
            )
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(KwpDecorList);
