import React, { createContext, useCallback, useState } from "react";
import { useContext } from "react";
import { get_product_by_query_object } from "../api/productApi";
import useAddressManager from "../hooks/address/useAddressManager";

// Create a context
export const ProductsListingContext = createContext();
export const useProductsListingContext = () => {
  return useContext(ProductsListingContext);
};
// Create a provider component
export const ProductsListingProvider = ({ children }) => {
  const { hubId } = useAddressManager();
  const [states, setStates] = useState({
    // Add your initial state here
    products: [],
    productsCount: "",
    productsListingQueryFilter: {},
    isLoading: false,
    page: 0,
    isLast: false,
    hasMore: true,
    categoriesByTags: {
      tagsObjects: [],
      selectedFilterTagObject: null,
    },
    headingName: "",
    subCategories: [],
    eachDecorHeight: 0,
    lastCardPreviewedIndex: 0,
    scrollPositions: {
      productsList: 0,
    },
  });

  const isDifferentFilter = useCallback(
    (filter) => {
      return (
        JSON.stringify(filter) !==
        JSON.stringify(states.productsListingQueryFilter)
      );
    },
    [states.productsListingQueryFilter]
  );
  /**
   * Fetches products based on the provided filter and updates the state accordingly.
   *
   * @param {Object} params - The parameters for fetching products.
   * @param {Object} params.filter - The filter object to apply when fetching products.
   * @param {number} [params.page=0] - The page number for pagination.
   * @param {string} [params.selectedFilterTag=states.categoriesByTags.selectedFilterTagObject] - The selected filter tag.
   *
   * @returns {Promise<void>} - A promise that resolves when the products have been fetched and the state has been updated.
   *
   * @async
   * @function getProducts
   *
   * @example
   * getProducts({ filter: { category: 'electronics' }, page: 1 });
   *
   * @description
   * This function fetches products using the provided filter and updates the state with the fetched products.
   * It handles pagination by adjusting the skip value based on the page number.
   * The function also updates the loading state and handles any errors that occur during the fetch.
   */
  const getProducts = useCallback(
    async ({
      filter,
      page = 0,
      selectedFilterTagObject = states.categoriesByTags.selectedFilterTagObject,
    }) => {
      const isRequestingDataForDifferentProductsSets =
        isDifferentFilter(filter) ||
        selectedFilterTagObject !==
          states.categoriesByTags.selectedFilterTagObject;

      if (!isRequestingDataForDifferentProductsSets && states.isLast) {
        return;
      }

      setStates((prev) => ({
        ...prev,
        isLoading: page === 0,
        page: page,
        productsListingQueryFilter: filter,
        categoriesByTags: {
          ...prev.categoriesByTags,
          selectedFilterTagObject,
        },
      }));

      try {
        // Fetch products using the provided filter; adjust skip based on the page
        const res = await get_product_by_query_object({
          queryObject: {},
          skip: page * 54,
          limit: 54,
          filter: {
            ...filter,
            hubId:
              hubId ||
              localStorage.getItem("U_LOCATION")?.hubId ||
              "MUM-THANE-HUB", // we are putting the default hub as MUM-THANE-HUB because some data missing comes in during direct page load of product list page
            active: true,

            selectedFilterTagObjectId: selectedFilterTagObject?._id,
            variantExclude: true,
          },
          tags: ["getProductsByFilter"],
        });

        setStates((prev) => {
          const newProducts =
            page === 0
              ? res.products
              : [...(prev.products || []), ...(res.products || [])];

          return {
            ...prev,
            products: newProducts,
            productsCount: res.productsCount,
            isLoading: false,
            page: page,
            isLast: res.isLast,
            hasMore: !res.isLast,
            categoriesByTags: {
              ...prev.categoriesByTags,
              tagsObjects: res.categoriesByTags,
            },
          };
        });
      } catch (error) {
        console.error(error.message);
        setStates((prev) => ({ ...prev, isLoading: false }));
      }
    },
    [
      hubId,
      states.categoriesByTags.selectedFilterTagObject,
      setStates,
      states.isLast,
      isDifferentFilter,
    ]
  );

  return (
    <ProductsListingContext.Provider
      value={{ states, setStates, getProducts, isDifferentFilter }}
    >
      {children}
    </ProductsListingContext.Provider>
  );
};
