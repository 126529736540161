import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaAngleLeft } from "react-icons/fa";
import ResponsiveContext from "../../../../../../context/ResponsiveContext";
import TextSearchBar from "./c/TextSearchBar";
import ServiceAvailable from "./c/ServiceAvailable";
import DefaultLocationSuggestions from "./c/DefaultLocationSuggestions";
import WarningModalv2 from "../../../../../../assets/WarningModalv2";
import Map from "./c/Map";
import GLobalStoreContext from "../../../../../../context/GlobalStoreContext";
import { getCurrentLocation } from "./c/CurrentLocation";

let queryDebounceTime = 600;
let autocompleteService;
let country = "IN";
let timeoutId;
let autoCompleteRef = null;
let mapContainerRef = null;
let minSearchTextLengthToStartSearch = 4;

export default function PickLocationFromMap({
  callback,
  handleClose,
  formData: parentStates,
}) {
  const { screenWidth } = useContext(ResponsiveContext);

  const { storeStates, setStoreStates } = useContext(GLobalStoreContext);

  const isSmallDevice = screenWidth / 16 <= 30;

  const [states, setStates] = useState({
    lat: parentStates?.locationv2?.lat || 20,
    lng: parentStates?.locationv2?.lng || 82,
    map: null,
    hubId: "",
    markers: {},
    address: "",
    placeId: "",
    predictions: [],
    currentLiveLocation: null,
    query: "",
    defaultUi: parentStates?.locationv2?.lat ? false : true,
    isChangeMapPosition: false,
    mapScriptLoaded: false,
    loadingCurrentLocation: false,
    searchBarResultAddress: "",
    isPredictionsShow: false,
    loadingPredictions: false,
    loadingPointLocation: false,
    showServiceCites: true,
    loadingReverseGeoCode: false,
    showMap: parentStates?.locationv2?.lat ? true : false,
    serviceAvailable: false,
    noServiceAvailable: false,
    loadingLocation: false,
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
  });

  useEffect(() => {
    onFetchClick({ isAutoFetching: true });
    return () => {
      const markers = states.markers;
      for (const key in markers) {
        delete markers[key];
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Check if the script is already loaded
    if (!storeStates.isGoogleMapsScriptLoaded) {
      // Load the Google Maps API script
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
      script.defer = true;
      document.head.appendChild(script);

      // Set the global variable to true once the script is loaded
      script.onload = () => {
        setStoreStates((p) => ({
          ...p,
          isGoogleMapsScriptLoaded: true,
        }));
        initMap();
      };

      // Cleanup to prevent memory leaks
      return () => {
        document.head.removeChild(script);
      };
    } else {
      // The script is already loaded, perform any necessary actions
      initMap();
    }

    // eslint-disable-next-line
  }, []);

  const initMap = () => {
    setStates((p) => ({ ...p, mapScriptLoaded: true }));
    // initializing the autocomplete service for getting predictions
    autocompleteService = new window.google.maps.places.AutocompleteService();
  };

  const onFetchClick = (props) => {
    let IS_LOCATION_FETCHED_ONES = sessionStorage.getItem(
      "IS_LOCATION_FETCHED_ONES"
    );

    if (!IS_LOCATION_FETCHED_ONES) {
      sessionStorage.setItem("IS_LOCATION_FETCHED_ONES", true);
    }

    if (IS_LOCATION_FETCHED_ONES && props?.isAutoFetching) {
      return;
    }

    setStates((p) => ({
      ...p,
      loadingCurrentLocation: true,
    }));
    getCurrentLocation(setStates)
      .then((location) => {
        setStates((p) => ({
          ...p,
          lat: location.lat,
          lng: location.lng,
          currentLiveLocation: { lat: location.lat, lng: location.lng },
          showMap: true,
          defaultUi: false,
          isChangeMapPosition: true,
          loadingCurrentLocation: false,
        }));
      })
      .catch((error) => {
        console.error("Error:", error);

        if (!props?.isAutoFetching) {
          setStates((p) => ({
            ...p,
            warningModel: {
              msg: "Something went wrong while fetching your location. Try to search your area",
              rightBtnText: "Ok",
              isRender: true,
            },
            loadingCurrentLocation: false,
          }));
        } else {
          setStates((p) => ({ ...p, loadingCurrentLocation: false }));
        }
      });
  };

  const handleConfirmLocation = () => {
    try {
      const addressLine1 =
        states.searchBarResultAddress?.split(",")[0] ||
        states.premise ||
        states.streetName ||
        states.route ||
        states.s_locality1 ||
        states.s_locality2 ||
        states.s_locality3 ||
        states.city ||
        states.pincode;

      const selectedLocation = {
        serviceAreaInfo: {
          isServiceable: states.serviceAvailable,
          isInAnyHub: states.hubId ? true : false,
          hubId: states.hubId,
        },
        lat: states.lat,
        lng: states.lng,
        addressLine1,
        city: states.city,
        state: states.state,
        pincode: states.pincode,
        addressLine2: states.searchBarResultAddress || states.formatedAddress,
      };

      callback(selectedLocation);

      handleClose(selectedLocation);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="fixed right-0 top-0 left-0 bottom-0 z-[999]">
      <div
        onClick={() => {
          handleClose();
        }}
        className="fixed right-0 top-0 left-0 bottom-0 z-[-1] bg-[#00000026]"
      ></div>
      <motion.div
        initial={
          isSmallDevice
            ? { translateY: 500, opacity: 0 }
            : { translateX: -500, opacity: 0 }
        }
        animate={
          isSmallDevice
            ? { translateY: 0, opacity: 1 }
            : { translateX: 0, opacity: 1 }
        }
        exit={
          isSmallDevice
            ? { translateY: 500, opacity: 0 }
            : { translateX: -500, opacity: 0 }
        }
        transition={{ duration: 0.3 }}
        className="w-full text-[.875rem] flex flex-col items-center md:border-r border-gray-200 max-w-[30rem] bg-[white] fixed top-0 left-0 bottom-0 z-[2] "
      >
        <div className="flex w-full gap-2 items-center bg-[white] p-4 pb-2 pt-3 z-[4] text-[1.125rem] font-[500] ">
          <FaAngleLeft onClick={handleClose} className="text-[1.25rem]" />
          <div>Select service location</div>
        </div>

        {/* location search bar (position: absolute) */}
        <TextSearchBar
          minSearchTextLengthToStartSearch={minSearchTextLengthToStartSearch}
          states={states}
          setStates={setStates}
          timeoutId={timeoutId}
          queryDebounceTime={queryDebounceTime}
          autocompleteService={autocompleteService}
          country={country}
        />

        <WarningModalv2 parentStates={states} setParentStates={setStates} />

        {/* the default initial ui (including the prediction search list) */}
        <DefaultLocationSuggestions
          states={states}
          onFetchClick={onFetchClick}
          setStates={setStates}
        />

        {/* if the selected location on the map is serviceable */}
        <ServiceAvailable
          states={states}
          onFetchClick={onFetchClick}
          handleConfirmLocation={handleConfirmLocation}
          setStates={setStates}
          autoCompleteRef={autoCompleteRef}
        />

        {/* the main google map */}
        {states.showMap && (
          <Map
            states={states}
            setStates={setStates}
            mapContainerRef={mapContainerRef}
          />
        )}
      </motion.div>
    </div>
  );
}
