import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarAddressv2 from "./NavbarAddressv2";
import SearchBar from "./search/SearchBar";
import { CiHeart, CiShoppingCart, CiUser } from "react-icons/ci";
import Profile from "./profileDetails/Profile";
import AuthContext from "../../context/AuthContext";
import CompRenderingContext from "../../context/CompRenderingContext";
import GLobalStoreContext from "../../context/GlobalStoreContext";
import useCartManager from "../../hooks/cart/useCartManager";

export default function NavbarLg() {
  const { pathname } = useLocation();
  // ----------------- ** CONTEXT ** --------------------
  const { user, logout } = useContext(AuthContext);
  const { wishlistedArrayObj } = useContext(GLobalStoreContext);

  const { setNavAddressPop } = useContext(CompRenderingContext);
  const navigate = useNavigate();
  const { cart } = useCartManager();

  const onWishListClick = () => {
    if (user) {
      navigate("/profile/wishlist");
    } else {
      window.goToLogin();
    }
  };

  const handleClickOnEboLogo = () => {
    setNavAddressPop(false);
  };

  if (pathname === "/delete/user") {
    return <></>;
  }

  return (
    <nav className={`navbar-i z-[9] hidden md:grid `}>
      <Link
        to="/"
        onClick={handleClickOnEboLogo}
        className="flex justify-center items-center flex-1"
      >
        <h1
          style={{
            fontFamily: "'Josefin Sans', sans-serif",
          }}
          className="  relative text-[2rem] flex justify-center items-end text-[#2136d4] font-[800]  "
        >
          ebo
        </h1>
      </Link>
      <NavbarAddressv2 />
      <SearchBar />

      <div className="flex justify-end gap-[1.5rem] items-center mr-[1rem] ">
        <div
          onClick={() => {
            !user && window.goToLogin();
          }}
          className="text-[1.5rem] flex flex-col items-center justify-center gap-1 text-[black]  cursor-pointer  nav-profile  hover:text-[#2136d4]"
        >
          <CiUser />
          <p className="  text-[.825rem]  ">{user ? "Profile" : "Login"}</p>

          {user !== null && <Profile user={user} logout={logout} />}
        </div>

        <div
          onClick={onWishListClick}
          className="text-[1.5rem] flex flex-col relative items-center justify-center gap-1 text-[black] cursor-pointer hover:text-red-600"
        >
          <CiHeart />
          <p className=" text-[.825rem]  ">Wishlist</p>
          {wishlistedArrayObj?.length > 0 && (
            <div className=" w-4 h-4 flex items-center justify-center rounded-full blue-gradient absolute -top-1 right-1 text-[.75rem]">
              {wishlistedArrayObj?.length}
            </div>
          )}
        </div>

        <div
          onClick={() => navigate("/checkout")}
          to={"/checkout"}
          className="text-[1.5rem] relative flex flex-col items-center justify-center gap-1 text-[black] cursor-pointer hover:text-[#2136d4]"
        >
          <CiShoppingCart />
          <p className="  text-[.825rem]   ">Cart</p>
          {cart?.product && (
            <div className=" w-4 h-4 flex items-center justify-center rounded-full blue-gradient absolute -top-1 -right-1 text-[.75rem]  ">
              1
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
