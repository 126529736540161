import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  memo,
  useCallback,
} from "react";
import CompRenderingContext from "./CompRenderingContext";
import userApi, {
  generate_otp,
  get_user,
  grant_access_for_admin,
  register_user,
} from "../api/userApi";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import GLobalStoreContext from "./GlobalStoreContext";
import userUtils from "../utils/user/userUtils";
import { useStoreContext } from "./StoreContext";

const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

let showLoginModalTimeout;

export const AuthProvider = memo(({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setUI,
    setStore,
    store: { cart },
  } = useStoreContext();
  const nav = useNavigate();
  // ------------ ** CONTEXTS ** ------------------------------

  const { setLoginModal, setIsLoginModal, locationData } =
    useContext(CompRenderingContext);
  const { handleContentLoadOnUserChange } = useContext(GLobalStoreContext);

  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // ---------------- ** STATES ** ------------------------------

  // otp state
  const [serverRes, setServerRes] = useState({
    isLoading: false,
    data: null,
  });

  //initial user state
  const [initialState, setInitialState] = useState({
    user: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    type: "",
  });
  //phone input state
  const [number, setNumber] = useState("");

  //state errors
  const [error, setError] = useState("");

  //form rendering on condition
  const [authPage, setAuthPage] = useState({
    login: true,
    otp: false,
    register: false,
  });

  useEffect(() => {
    if (initialState.user?.id) {
      setUI((p) => ({
        ...p,
        home: {
          ...p.home,
          nav: {
            ...p.home.nav,
            dropBannerURI:
              "https://img.ebonow.com/Posters/dropbanner/post_login.mp4",

            bannerPoster:
              "https://img.ebonow.com/Posters/dropbanner/post_login.webp",
          },
        },
      }));
    } else {
      setUI((p) => ({
        ...p,
        home: {
          ...p.home,
          nav: {
            ...p.home.nav,
            dropBannerURI:
              "https://img.ebonow.com/Posters/dropbanner/pre_login.mp4",
            bannerPoster:
              "https://img.ebonow.com/Posters/dropbanner/pre_login.webp",
          },
        },
      }));
    }

    // eslint-disable-next-line
  }, [initialState.user?.id]);

  // ---------- ** USE EFFECTS ** ----------------

  useEffect(() => {
    // wishlist first time load
    // handleContentLoadOnUserChange function contains wishlist first time load logic
    handleContentLoadOnUserChange();

    const { pathname } = location;
    if (
      initialState.user === null &&
      !locationData.isLocationAddModalRender &&
      pathname === "/"
    ) {
      showLoginModalTimeout && clearTimeout(showLoginModalTimeout);
      showLoginModalTimeout = setTimeout(() => {
        setLoginModal(true);
      }, 30000);
    }

    return () => {
      setLoginModal(false);
      clearTimeout(showLoginModalTimeout);
    };
    //eslint-disable-next-line
  }, [initialState.user]);

  useEffect(() => {
    const accessToken = searchParams.get("accessToken");
    if (accessToken) {
      window && handleAdminAccess(accessToken);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  // ----------------- ** FUNCTIONS ** ----------------
  const handleAdminAccess = async (accessToken) => {
    localStorage.clear();
    console.log("access token", accessToken);
    const res = await grant_access_for_admin({
      url: REACT_APP_BACKEND_URL,
      accessToken,
    });

    if (res.isSuccess) {
      setInitialState({
        ...initialState,
        user: res.user,
        isLoading: false,
      });
      localStorage.setItem("user", JSON.stringify(res.user));
    }
    handleRemoveQueryParams();
  };

  const handleRemoveQueryParams = () => {
    // Extract the pathname from the current location
    const { pathname } = location;

    // Replace the current URL without query parameters
    navigate(pathname);
  };

  //for closing sidebar
  const closeSideBar = useCallback(() => {
    setIsLoginModal(false);
    //eslint-disable-next-line
  }, []);

  /**
   * initialize the opt generation
   */
  const otpGeneration = async ({ type, inputNumber }) => {
    try {
      setServerRes({
        ...serverRes,
        isLoading: true,
      });
      const res = await generate_otp({
        inputNumber,
        type: type || "sms",
      });
      if (res.isSuccess) {
        setServerRes({
          isLoading: false,
          data: res,
        });
        // will open the otp page
        setAuthPage({
          ...authPage,
          otp: true,
          login: false,
        });
        window.toast("OTP Sent to your phone number");
      } else {
        setServerRes({
          ...serverRes,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = useCallback(async () => {
    try {
      setInitialState({
        ...initialState,
        isLoading: true,
      });
      const res = await get_user({ url: REACT_APP_BACKEND_URL, number });
      if (res.isSuccess) {
        setInitialState({
          ...initialState,
          user: res.user,
          isLoading: false,
        });
        window && localStorage.setItem("user", JSON.stringify(res.user));
        // window.location.reload();
      } else {
        setInitialState({
          ...initialState,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      setInitialState({
        ...initialState,
        isLoading: false,
      });
    }
  }, [REACT_APP_BACKEND_URL, number, initialState]);

  // registering new user
  const registerUser = async (formData) => {
    try {
      let lastSelectedLocation = localStorage.getItem("lastSelectedLocation");
      if (lastSelectedLocation) {
        lastSelectedLocation = JSON.parse(lastSelectedLocation);
      }

      setInitialState((prev) => ({ ...prev, isLoading: true }));

      const res = await register_user({
        url: REACT_APP_BACKEND_URL,
        formdata: { ...formData, lastSelectedLocation },
      });

      if (res.isSuccess) {
        localStorage.setItem("user", JSON.stringify(res.user));

        setInitialState((prev) => ({
          ...prev,
          isLoading: false,
          user: res.user,
        }));
        setStore((p) => ({
          ...p,
          user: res.user,
          cart: res?.data?.cart?.product ? res?.data?.cart : p.cart,
          addresses: res.data?.addresses || [],
          selectedLocation: res.data?.selectedLocation,
        }));

        setIsLoginModal(false);
        setLoginModal(false);

        setAuthPage({ login: true, otp: false, register: false });
        if (cart || res.data?.cart) {
          navigate("/checkout");
        } else {
          navigate("/");
        }
      } else {
        setInitialState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    } catch (error) {
      console.log(error);
      setInitialState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  //log out a user
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const resendOTPFunc = async ({ type, inputNumber }) => {
    await otpGeneration({ type, inputNumber });
  };

  //input border css
  const inputBorder = "border-b-[1px] border-[rgba(0,0,0,0.3)] py-3 my-3";

  return (
    <AuthContext.Provider
      value={{
        user: initialState.user,
        inputBorder,
        authPage,
        setAuthPage,
        number,
        setNumber,
        initialState,
        setInitialState,
        closeSideBar,
        registerUser,
        logout,
        error,
        setError,
        resendOTPFunc,
        editUser: async (userData) => {
          let updateObject = {
            name: userData.name,
            email: userData.email,
            dateOfBirth: userData.dateOfBirth || "",
            gender: userData.gender,
          };
          const res = await userApi().updateUser(updateObject);

          if (res.isSuccess) {
            setInitialState((p) => {
              let user = { ...p.user, ...updateObject };

              localStorage.setItem("user", JSON.stringify(user));
              return {
                ...p,
                user,
              };
            });
            setStore((p) => {
              return {
                ...p,
                user: {
                  ...p.user,
                  ...updateObject,
                },
              };
            });
          } else {
            window.toast("Something went wrong!");
          }
        },
        otpGeneration,
        checkOTP: async (inputOTP) => {
          const res = await userUtils().checkOTP({
            inputOTP,
            phoneNumber: number,
            hashOtp: serverRes.data.hash,
            setIsLoginModal,
            setAuthPage,
            setInitialState,
            setLoginModal,
            setStore,
            navigateToNav: () => {
              nav("/register");
            },
          });
          return res;
        },
        serverRes,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export default AuthContext;
