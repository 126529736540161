import { useCallback } from "react";
import { createOrUpdateCartApi, delete_cart } from "../../api/cartApi";
import { useAuthContext } from "../../context/AuthContext";
import { useStoreContext } from "../../context/StoreContext";
import cartUtils from "../../utils/cartUtils";
import eboUtils from "../../utils/eboUtils";
import useAddressManager from "../address/useAddressManager";

const RE_UPDATE_CART_DEBOUNCE_TIME = 5000; // 5 SECONDS

export default function useCartManager() {
  const {
    store: { cart, addresses, selectedLocation },
    offerState: { offers },
    setStore,
    cartUpdateTimeoutId,
  } = useStoreContext();

  const { hubId: selectedHub } = useAddressManager();
  const {
    initialState: { user },
  } = useAuthContext();

  /* 

  ** cartPrice **  
  - total price of the cart.
  - product cost price + addons cost price + convenience fee

  ** buyingPrice *
  - final cart price on which the user can purchase the decor.
  - product selling price + addons selling price - coupon discount + convenience fee

  ** santa tip **
  - It will not be added in buyingPrice or cartPrice. 
 
  */

  const addToCart = async (cartToBeAdded = {}, propUser = user) => {
    // cart => { product ( required ) , ... other keys that we need to add in the start of the cart addition  }
    try {
      const cartToBeAddedKeyValue = Object.entries(cartToBeAdded);

      const structuredCart = await constructCart(
        cartToBeAddedKeyValue,
        propUser
      );

      setStore((p) => ({
        ...p,
        cart: structuredCart || {},
      }));

      // add to the data base, without waiting for the response
      if (!structuredCart?._id) return; // if _id not exists then user is not logged in

      createOrUpdateCartApi(dePopulateCart(structuredCart)).then((res) => {
        if (res.isSuccess) {
          // cart has been updated successfully
        } else {
          window.toast(`Cart couldn't be added. Please try again later.`);
          setStore((p) => ({
            ...p,
            cart: {},
          }));
        }
      });

      return structuredCart;
    } catch (error) {
      console.error(error);
    }
  };

  const removeFromCart = async () => {
    // remove the cart from the database
    try {
      setStore((p) => ({
        ...p,
        cart: {},
      }));

      localStorage.removeItem("cart");
      sessionStorage.removeItem("isCouponAutoApplyDone");
      sessionStorage.removeItem("isOpenCouponAppliedModal");

      if (!cart?._id) return;

      const res = await delete_cart({ _id: cart._id });

      if (res.isSuccess) {
        // cart has been updated successfully
      } else {
        window.toast(`Cart couldn't be removed. Please try again later.`);
        setStore((p) => ({
          ...p,
          cart: {},
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Constructs and updates a shopping cart object based on provided changes and user information.
   *
   * This function modifies the cart state by merging new key-value pairs into the existing cart. If the cart already has an updated user
   * object, that user is used; otherwise, it integrates the provided user details (excluding sensitive tokens) to form a unique cart identifier.
   * It also handles special cases such as transforming offers into the required object structure and ensures default values for essential fields.
   * Additionally, the function calculates pricing based on the product price, add-ons, and coupon discounts before persisting the updated cart in local storage.
   *
   * @param {Array<Array<any>>} [changedKeyValues=[]] - A list of key-value pairs representing modifications to apply to the cart.
   *   If the key is 'offers' and the value array contains offer objects, it ensures the proper structure is maintained.
   * @param {Object} [propUser=user] - The user object used for cart association if the cart does not already include an updated user.
   *   The provided user object is filtered to exclude sensitive tokens. If a user is already present in the cart state, that user is retained.
   *
   * @returns {Object} The updated cart object. Returns an empty object if the cart does not contain a product.
   *
   * @throws {Error} Logs any errors encountered during the cart construction process.
   */
  const constructCart = async (changedKeyValues = [], propUser = user) => {
    try {
      let tempCart = { ...cart };

      let userObj = propUser
        ? Object.fromEntries(
            Object.entries(propUser).filter(([key]) => key !== "token")
          )
        : {};

      if (userObj.id) {
        userObj._id = userObj.id;
        delete userObj.id;
      }

      // updating the cart with the changed key values
      changedKeyValues.forEach(([key, value]) => {
        if (key === "offers" && value?.[0]?.offerCode) {
          // if offers array has elements with direct offer object ( destructured ), then we need to convert it into a proper object => { offer: offerObject }
          tempCart.offers = [
            {
              offer: value?.[0],
            },
          ];
        } else {
          tempCart[key] = value;
        }
      });

      // if the cart don't have the product then we will not do anything with the cart
      if (!tempCart.product) return {}; // means cart is not present

      tempCart["_id"] = userObj?._id ? userObj._id + "C" : null;

      tempCart["user"] = userObj;

      // Ensure required fields have default values
      tempCart.addOns = tempCart.addOns || [];
      tempCart.offers = tempCart.offers || [];
      tempCart["santaTip"] = tempCart.santaTip || 0;

      if (
        !tempCart?.bookingAddress &&
        (addresses?.length > 0 || selectedLocation?._id)
      ) {
        if (selectedLocation?._id) {
          tempCart["bookingAddress"] = selectedLocation;
        } else {
          const validAddress = addresses.find(
            (address) => address?.locationv2?.lat
          );
          tempCart["bookingAddress"] = validAddress;
        }
      }

      const cartHubInformation = await getCartHubStatus(tempCart);

      tempCart["hubId"] = cartHubInformation.hubId;

      let cartPricing = calculateAdvancedCartPricing(tempCart);

      tempCart["buyingPrice"] = cartPricing.buyingPrice;

      tempCart["cartPrice"] = cartPricing.cartPrice;

      if (cartPricing?.couponDiscount === 0) {
        tempCart["offers"] = [];
        cartPricing = calculateAdvancedCartPricing(tempCart);
      }

      // auto coupon validation and applying the coupon
      if (tempCart.offers?.length === 0) {
        const isCouponAutoApplyDone = sessionStorage.getItem(
          "isCouponAutoApplyDone"
        );

        if (!isCouponAutoApplyDone) {
          const couponsDetails = cartUtils(tempCart).getCoupons(
            offers,
            tempCart?.hubId
          );
          const bestValidCoupon = couponsDetails.bestValidCoupon;

          if (bestValidCoupon) {
            tempCart.offers = [{ offer: bestValidCoupon }];

            sessionStorage.setItem("isCouponAutoApplyDone", true);
            sessionStorage.setItem("isOpenCouponAppliedModal", true);

            // re calculating the cart price
            const cartPricing = calculateAdvancedCartPricing(tempCart);

            tempCart["buyingPrice"] = cartPricing.buyingPrice;
            tempCart["cartPrice"] = cartPricing.cartPrice;
          }
        }
      }

      // any changes for the cart will pass through this function
      localStorage.setItem("cart", JSON.stringify(tempCart));

      return tempCart;
    } catch (error) {
      console.error(error);
    }
  };

  const getCartHubStatus = async (propCart = cart, isAddressChanging) => {
    try {
      const getHubIdForTheAddress = async (address) => {
        const serviceAreaInfo = await eboUtils().getServiceAreaInfo({
          lat: address.locationv2?.lat,
          lng: address.locationv2?.lng,
        });

        return serviceAreaInfo?.hubs[0]?.hubId;
      };

      if (isAddressChanging) {
        const hubId = await getHubIdForTheAddress(propCart?.bookingAddress);
        return { hubId, isChanged: hubId !== propCart?.hubId };
      }

      if (propCart?.hubId) {
        // if the hubId is already present in the cart then we will return that hubId
        return { hubId: propCart?.hubId, isChanged: false };
      }

      if (propCart.bookingAddress?._id) {
        const hubId = await getHubIdForTheAddress(propCart?.bookingAddress);
        return { hubId, isChanged: hubId !== propCart?.hubId };
      }

      return { hubId: selectedHub, isChanged: true };
    } catch (error) {
      console.error(error);
      // we are returning the selected hub as the hubId and isChanged as true just for the safety purpose so that the cart doesn't break
      return { hubId: selectedHub, isChanged: true };
    }
  };

  const updateCartToDB = async (cartToBeUpdated = cart) => {
    try {
      if (!cartToBeUpdated?._id) return console.error("Cart Id is not present");

      clearTimeout(cartUpdateTimeoutId.current);

      cartUpdateTimeoutId.current = setTimeout(() => {
        createOrUpdateCartApi(dePopulateCart(cartToBeUpdated)).then((res) => {
          if (res.isSuccess) {
            // cart has been updated successfully
          } else {
            window.toast(`Cart couldn't be updated. Please try again later.`);
          }
        });
      }, RE_UPDATE_CART_DEBOUNCE_TIME);
    } catch (error) {
      console.error(error);
    }
  };

  const dePopulateCart = (propCart = cart) => {
    try {
      return {
        ...propCart,
        user: user?._id || user?.id,
        addOns: propCart.addOns?.map((addon) => ({
          ...addon,
          addon: addon.addon._id,
        })),
        bookingAddress: propCart.bookingAddress?._id,
        freebie: propCart.freebie?._id,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const updateCart = async (changedKeyValues = []) => {
    // changedKeyValues => [ [key , value], ... ]
    try {
      const tempCart = await constructCart(changedKeyValues);
      setStore((p) => ({
        ...p,
        cart: tempCart,
      }));

      if (!tempCart._id) return; // if _id not exists then user is not logged in

      // update the cart in the database
      updateCartToDB(tempCart);
    } catch (error) {
      console.error(error);
    }
  };

  const addonsManager = ({ type = "add", addons }) => {
    // type => add, remove, update, qtyIncrement, qtyDecrement,
    try {
      let tempAddons = [
        ...(cart.addOns?.filter((curr) => curr?.quantity > 0) || []),
      ];

      if (type === "remove" || type === "update" || type === "add") {
        // for remove, update and add we need to filter the addons
        // filter the addons that are already in the cart
        tempAddons = tempAddons.filter((addon) => {
          return !addons.some((curr) => curr.addon._id === addon.addon._id);
        });
      }
      if (type === "add" || type === "update") {
        // for add and update we need to add the addons
        // now concat the addons
        tempAddons = [...tempAddons, ...addons];
      }

      if (type === "qtyIncrement" || type === "qtyDecrement") {
        // for qtyIncrement and qtyDecrement we need to update the quantity of the addons
        addons.forEach((addon) => {
          const index = tempAddons.findIndex(
            (curr) => curr.addon._id === addon.addon._id
          );
          if (index !== -1) {
            tempAddons[index].quantity += type === "qtyIncrement" ? 1 : -1;
            if (tempAddons[index].quantity <= 0) {
              tempAddons.splice(index, 1);
            }
          }
        });
      }
      // update the cart
      updateCart([["addOns", tempAddons]]);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Calculates an advanced breakdown of all pricing details for the current cart.
   *
   * This function computes:
   *  1. addonsSellingPrice: Total selling price for all add-ons.
   *  2. addonsCostPrice: Total cost price for all add-ons.
   *  3. couponDiscount: Discount applied from the coupon on the product selling price.
   *  4. productSellingPrice: Selling price of the product for the selected hub.
   *  5. productCostPrice: Cost price of the product for the selected hub.
   * *  productWebDiscount: Web discount applied on the product.
   *  6. convenienceFee: The fee applied for convenience (defaults to 0 if not present).
   *  7. santaTip: Additional tip (defaults to 0 if not set).
   *  8. cartPrice: Sum of addonsCostPrice, productCostPrice, and convenienceFee.
   *  9. buyingPrice: Sum of addonsSellingPrice, productSellingPrice, convenienceFee minus couponDiscount.
   * 10. amountToPay: buyingPrice plus santaTip.
   * 11. advancePrepayDiscount: Discount calculated for advance prepayment.
   * 12. fullPrepayDiscount: Discount calculated for full prepayment.
   * 13. savings: Total savings computed as couponDiscount + (productCostPrice - productSellingPrice) + (addonsCostPrice - addonsSellingPrice).
   * 14. couponValidationPrice: The total price used for coupon validation (productSellingPrice + addonsSellingPrice).
   *
   * Note: This function assumes that `cart`, `cartHubId`, and helper functions such as
   *       `cartUtils().calculateCouponDiscount` and `calculate_prepaid_discount` are available in the current scope.
   *
   * @returns {Object} An object containing all the computed pricing details.
   */
  const calculateAdvancedCartPricing = useCallback(
    (propCart = cart) => {
      try {
        // If there is no valid cart or product, return default pricing details.
        if (!propCart || !propCart.product || !propCart.hubId) {
          return {
            addonsSellingPrice: 0,
            addonsCostPrice: 0,
            couponDiscount: 0,
            productSellingPrice: 0,
            productCostPrice: 0,
            convenienceFee: 0,
            santaTip: 0,
            cartPrice: 0,
            buyingPrice: 0,
            amountToPay: 0,
            advancePrepayDiscount: 0,
            fullPrepayDiscount: 0,
            savings: 0,
            coupon: null,
            couponValidationPrice: 0,
            discountIncludingCoupon: 0,
          };
        }

        const cartHubId = propCart.hubId;

        // Extract product pricing for the selected hub.
        const productPricing =
          propCart.product.listingPriceHubWise?.find(
            (hubData) => hubData.hub === cartHubId
          ) || {};

        const productSellingPrice = productPricing.sellingPrice || 0;
        const productCostPrice = productPricing.costPrice || 0;
        const productWebDiscount = productPricing.webDiscount || 0;

        // Initialize addon pricing values.
        let addonsSellingPrice = 0;
        let addonsCostPrice = 0;

        // Loop through all add-ons in the propCart.
        if (Array.isArray(propCart.addOns)) {
          propCart.addOns.forEach((addonItem) => {
            const addonPriceDetail = addonItem.addon.listingPriceHubWise?.find(
              (hubData) => hubData.hub === cartHubId
            );
            if (addonPriceDetail) {
              addonsSellingPrice +=
                addonPriceDetail.sellingPrice * addonItem.quantity;
              addonsCostPrice +=
                addonPriceDetail.costPrice * addonItem.quantity;
            }
          });
        }

        // Retrieve convenienceFee and santaTip, defaulting to 0 if they aren't defined.
        const convenienceFee = propCart.convenienceFee || 0;
        const santaTip = propCart.santaTip || 0;

        const couponValidationPrice = productSellingPrice + addonsSellingPrice;

        // Calculate coupon discount using a helper from cartUtils.
        const couponDiscount =
          cartUtils().calculateCouponDiscount(
            propCart.offers?.[0],
            productSellingPrice,
            couponValidationPrice
          ) || 0;

        // Calculate overall cart and buying prices.
        const cartPrice = addonsCostPrice + productCostPrice + convenienceFee;
        const buyingPrice =
          addonsSellingPrice +
          productSellingPrice +
          convenienceFee -
          couponDiscount;

        // Calculate the final amount the user needs to pay.
        const amountToPay = buyingPrice + santaTip;

        // Calculate prepaid discounts using an external helper function.
        const advancePrepayDiscount = eboUtils().calculate_prepaid_discount(
          buyingPrice,
          "advance"
        );

        const fullPrepayDiscount = eboUtils().calculate_prepaid_discount(
          buyingPrice,
          "full"
        );

        const discountIncludingCoupon = Math.round(
          // discount in percentage: web discount + coupon discount
          ((parseInt(productCostPrice) -
            (parseInt(productSellingPrice) - parseInt(couponDiscount || 0))) *
            100) /
            parseInt(productCostPrice)
        );

        // Calculate total savings.
        const savings =
          couponDiscount +
          (productCostPrice - productSellingPrice) +
          (addonsCostPrice - addonsSellingPrice);

        // Return the computed pricing breakdown.
        return {
          addonsSellingPrice,
          addonsCostPrice,
          couponDiscount,
          productSellingPrice,
          productCostPrice,
          productWebDiscount,
          convenienceFee,
          santaTip,
          cartPrice,
          buyingPrice,
          amountToPay,
          advancePrepayDiscount,
          fullPrepayDiscount,
          savings,
          coupon: propCart?.offers?.[0]?.offer || null,
          offerCode: propCart?.offers?.[0]?.offer?.offerCode,
          couponValidationPrice,
          discountIncludingCoupon,
        };
      } catch (error) {
        console.error("Error calculating advanced cart pricing:", error);
        return {};
      }
    },
    [cart]
  );

  return {
    // state
    cart,
    hasCart: !!cart?.product,
    // methods
    addToCart,
    removeFromCart,
    updateCart,
    addonsManager,
    calculateAdvancedCartPricing,
  };
}
