import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { FaUser, FaUsers } from "react-icons/fa";

export default function ReceiverDetailForm({ formData, user, setFormData }) {
  const [compData, setCompData] = useState({
    isEdit: false,
  });
  return (
    <>
      <div className="font-[400] text-[gray] text-[.875rem] mt-1 mb-1">
        Who you are booking for?
      </div>
      <div className="flex text-[.825rem] mb-2 font-[400] gap-4">
        <div
          onClick={() => {
            setFormData((p) => ({
              ...p,
              receiversDetails: {
                ...p.receiversDetails,
                type: "mySelf",
                name: user.name,
                mobileNumber: parseInt(user.phoneNumber),
              },
            }));
            setCompData({
              isEdit: false,
            });
          }}
          style={
            formData.receiversDetails.type === "mySelf"
              ? {
                  backgroundColor: "#F0F5FE",
                  borderColor: "#a3c1fe",
                  color: "#2136d4",
                  fontWeight: 500,
                }
              : { opacity: 0.6 }
          }
          className="border mt-1 cursor-pointer border-gray-200 px-2 flex items-center gap-1 py-1 rounded-md "
        >
          {" "}
          <FaUser className="relative bottom-[1px] " />
          Myself{" "}
        </div>
        <div
          onClick={() => {
            setFormData((p) => ({
              ...p,
              receiversDetails: {
                ...p.receiversDetails,
                type: "someoneElse",
                name: "",
                mobileNumber: "",
              },
            }));
            setCompData({
              isEdit: true,
            });
          }}
          style={
            formData.receiversDetails.type === "someoneElse"
              ? {
                  backgroundColor: "#F0F5FE",
                  borderColor: "#a3c1fe",
                  color: "#2136d4",
                  fontWeight: 500,
                }
              : { opacity: 0.6 }
          }
          className="border mt-1 cursor-pointer border-gray-200 px-2 flex items-center gap-1 py-1 rounded-md "
        >
          <FaUsers className="relative bottom-[1px] " />
          Someone else{" "}
        </div>
      </div>
      {compData.isEdit ? (
        <>
          <input
            type="text"
            value={formData.receiversDetails.name}
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                receiversDetails: {
                  ...p.receiversDetails,
                  name: e.target.value,
                },
              }))
            }
            className="p-3 border mt-3 text-[.825rem] rounded-md"
            placeholder="Enter receiver's name"
          />
          <input
            type="number"
            value={formData.receiversDetails.mobileNumber}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              setFormData((p) => ({
                ...p,
                receiversDetails: {
                  ...p.receiversDetails,
                  mobileNumber: parseInt(e.target.value),
                },
              }));
            }}
            className="p-3 border mt-3 text-[.825rem] rounded-md"
            placeholder="Enter receiver's phone number"
          />
        </>
      ) : (
        <div
          onClick={() => {
            setCompData({
              isEdit: true,
            });
          }}
          className="flex justify-between items-center border mt-2 cursor-pointer border-[#eeeeee] p-3 rounded-md "
        >
          <div className="flex flex-col  ">
            <div className="font-[500]  text-[.775rem] ">
              {formData.receiversDetails.type === "mySelf"
                ? "Your "
                : "Receivers "}
              details
            </div>{" "}
            <div className="font-[400] capitalize text-[grey] text-[.825rem] ">
              {formData.receiversDetails.name}
              {formData.receiversDetails.mobileNumber &&
                ", " + formData.receiversDetails.mobileNumber}
            </div>
          </div>
          <FaAngleRight className="text-[#adadad]" />
        </div>
      )}
    </>
  );
}
