import React from "react";
import { useLocation } from "react-router-dom";

export default function MetaHelmet({ title, description, url }) {
  const location = useLocation();

  const defaultUrl = "wwww.ebonow.com" + location.pathname;

  const defaultDescription =
    "ebo is a leading party decoration service provider. We specialize in creating stunning balloon arrangements for birthday, anniversary, and more. Checkout our cool dcors...";

  let defaultTitle = getDefaultTitle(url || location.pathname);

  return (
    <>
      <link rel="canonical" href={url || defaultUrl} />
      {/* <!-- Primary Meta Tags --> */}
      <title>{title || defaultTitle}</title>
      <meta name="title" content={title || defaultTitle} />
      <meta name="description" content={description || defaultDescription} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:url" content={url || defaultUrl} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />

      {/* <!-- Twitter --> */}
      <meta property="twitter:url" content={url || defaultUrl} />
      <meta property="twitter:title" content={title || defaultTitle} />
      <meta
        property="twitter:description"
        content={description || defaultDescription}
      />
    </>
  );
}

const getDefaultTitle = (url) => {
  if (url.includes("/kw/")) {
    // get the keyword
    let keyword = url.split("/kw/")[1]?.split("/")[0]?.split("-").join(" ");

    // making the first letter capital
    keyword = keyword.charAt(0).toUpperCase() + keyword.slice(1);

    return `${keyword} - ebo`;
  }

  if (url.includes("/products/decor/")) {
    // /products/decor/CLB59/White-and-Pink-Anniversary-Balloon-Decor
    // get the product name
    const productName = url
      .split("/products/decor/")[1]
      ?.split("/")[1]
      ?.split("-")
      .join(" ");

    return `${productName} - ebo`;
  }

  return "ebo - celebration made easy";
};
