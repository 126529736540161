import React from "react";
import { useAuthContext } from "../../../../context/AuthContext";
import { HiMiniArrowTrendingDown } from "react-icons/hi2";
import { useComponentRenders } from "../../../../context/CompRenderingContext";
import { BsBank2 } from "react-icons/bs";
import useAddressManager from "../../../../hooks/address/useAddressManager";
import { MdNotListedLocation } from "react-icons/md";
import useProduct from "../../../../hooks/product/useProduct";
import SecondRow from "./c/SecondRow";
import "./styles/price.css";

function Price({ product }) {
  const { pricing } = useProduct({ product });
  const { hubId } = useAddressManager();
  const { setLocationData } = useComponentRenders();

  if (pricing.isCalculating) {
    return (
      <div className=" mt-1 flex-col ">
        <div className=" flex w-[8rem] h-[.75rem] rounded-lg ebo_skeleton  "></div>
        <div className=" flex w-[5rem] mt-2 h-[.75rem] rounded-lg ebo_skeleton  "></div>
      </div>
    );
  }

  if (!hubId) {
    return (
      <div
        onClick={() =>
          setLocationData((p) => ({
            ...p,
            isLocationAddModalRender: true,
          }))
        }
        className=" mirror-Animation before:w-[10px] bg-gradient-to-l from-blue-50 to-white justify-between rounded-r-full flex mirror-Animation bg-[white] mt-1 items-center pr-2 mx-2 mb-2 py-2 gap-1"
      >
        <div className="flex flex-col whitespace-nowrap ">
          <div className="text-[1rem] leading-4 text-[#2136d4] font-[500]">
            Set location
          </div>
          <div className="text-[.75rem] text-[grey] leading-[15px]">
            To view price
          </div>
        </div>
        <MdNotListedLocation className="text-[#2136d4] text-[1.8rem]" />
      </div>
    );
  }

  if (pricing.isOnBooking || !pricing.sellingPrice) {
    return (
      <>
        <p className="flex gap-3 mt-2 font-[500] text-[.825rem] items-center">
          *Price on request
        </p>
        <p className="mirror-Animation font-[500] before:w-[20px] bg-gradient-to-r  from-blue-50 to-white rounded-[15px] p-1 px-2  mt-[2px] relative right-[4px] flex items-center gap-1  text-[.75rem] text-[#2136d4]  md:text-[.825rem]">
          Enquire for price
        </p>
      </>
    );
  }

  return (
    <div className=" mt-1 flex-col ">
      <SecondRow pricing={pricing} />
      <div className="pt-1" />
      <OfferPrice pricing={pricing} />
    </div>
  );
}

const OfferPrice = ({ pricing }) => {
  const {
    initialState: { user },
  } = useAuthContext();

  const { setLoginModal } = useComponentRenders();

  return (
    <div
      onClick={(e) => {
        if (!user) {
          e.stopPropagation();
          setLoginModal(true);
        }
      }}
      className="w-[105%]  pl-2 py-1 mt-[2px] relative right-[9px]
     bg-gradient-to-r from-blue-100 to-white  before:w-[20px] mirror-Animation
     flex items-center  gap-[2px]  rounded-l-[15px] 
     font-[600] text-[10px] text-[#2136d4]  md:text-[.8125rem]  whitespace-nowrap"
    >
      {/* if user is not logged in */}
      {!user && (
        <>
          <span className="  mr-[1px] scale-translate-animation  ">
            ₹{pricing?.sellingPrice - pricing.offerDiscount}
          </span>
          Login to drop
          <HiMiniArrowTrendingDown className=" flex-shrink-0 mx-[1px] text-[12px] drop-icon-animation " />{" "}
          price
        </>
      )}

      {/* if user is logged in and product has a offer discount */}
      {user && pricing.offerDiscount > 0 && (
        <>
          <HiMiniArrowTrendingDown className=" flex-shrink-0 mx-[1px] text-[12px] drop-icon-animation " />{" "}
          <div className="flex-col items-center font-[600] text-[10px] ml-[1px] ">
            <span className=" ">
              ₹
              {pricing.sellingPrice -
                pricing.offerDiscount -
                pricing.fullPrepaidDiscount}
            </span>
          </div>
          | Use
          <span className=" font-[600] ">{pricing?.offerCode}</span> +{" "}
          <BsBank2 className="relative flex-shrink-0 bottom-[1px]" />{" "}
          <span className=" font-[600] ">offer</span>
        </>
      )}

      {/* if the user is logged in and the product don't have any offer discount */}
      {user && pricing.offerDiscount === 0 && (
        <>
          <HiMiniArrowTrendingDown className=" flex-shrink-0 mx-[1px] text-[12px] drop-icon-animation " />{" "}
          <div className="flex-col items-center font-[600] text-[10px] ml-[1px] ">
            <span className=" ">
              ₹
              {pricing.sellingPrice -
                pricing.offerDiscount -
                pricing.fullPrepaidDiscount}
            </span>
          </div>
          | <BsBank2 className="relative flex-shrink-0 bottom-[1px]" />{" "}
          <span className=" font-[600] ">Prepaid offer</span>
        </>
      )}
    </div>
  );
};

export default React.memo(Price);
