import moment from "moment";
import { v2Api } from "../api/v2.api";
import { calculate_prepaid_discount } from "./pricingRules/calculatePrepaidDiscount";
import userUtils from "./user/userUtils";
import axios from "axios";

const getServiceAreaInfo = async ({ lat, lng }) => {
  try {
    const res = await v2Api({
      operation: "getServiceAreaInfo",
      location: {
        lat,
        lng,
      },
    });

    return res?.serviceInfo;
  } catch (error) {
    console.error(error);
  }
};
const getServiceableCities = async () => {
  try {
    const res = await v2Api({
      operation: "getServiceableCities",
    });

    return JSON.parse(res?.serviceableCities || {});
  } catch (error) {
    console.error(error);
  }
};

const getIPInfo = async () => {
  try {
    const ipApiRes = await axios.get("https://ipapi.co/json/");
    const ipInfo = {
      lat: ipApiRes?.data?.latitude,
      lng: ipApiRes?.data?.longitude,
      date: moment().toISOString(),
    };
    localStorage.setItem("userIP", JSON.stringify(ipInfo));

    return ipInfo;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default function eboUtils() {
  const callingNumber = "+919930699955";
  const whatsappNumber = "+919930699955";

  return {
    userUtils,
    callingNumber,
    whatsappNumber,
    getServiceAreaInfo,
    getServiceableCities,
    calculate_prepaid_discount,
    getIPInfo,
  };
}
