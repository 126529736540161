import React, { createContext, useContext, useRef, useState } from "react";

const StoreContext = createContext();
export const useStoreContext = () => useContext(StoreContext);

export const StoreProvider = ({ children }) => {
  const cartUpdateTimeoutId = useRef(null);
  const offerModalRef = useRef(null);

  const [store, setStore] = useState({
    user: {},
    addresses: [],
    selectedLocation: null, // { serviceAreaInfo: { hubId , ...  } , ... }
    cart: {
      // contains the user's cart data
    },

    freebies: [],
    serviceableCities: {},
  });

  const [offerState, setOfferState] = useState({
    offers: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    type: "",
  });

  const [UI, setUI] = useState({
    home: {
      nav: {
        type: "dropBanner",
        backgroundThemeMode: "light",
        dropBannerURI: "",
        bannerPoster: "",
        isDropBannerVisible: false,
        // total banner height will be the sum of bannerHeight and bannerExtraHeight
        bannerExtraHeight: (window.innerWidth * 435) / 480, // we will assume for a 1:1 ratio of extra banner height
        bannerHeight: (window.innerWidth * 285) / 480, // calculated from the banner ratio ( height / width )
        bannerRatio: 285 / 480, // this is the actual banner height ratio ( the main content after the navbar ) ( height / width)
        navbarHeight: 173, // this value will be updated according to the navbar height
        bannerClickLayers: {
          bottomLayerRatio: 100 / 480,
        },
      },
    },
  });

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
        UI,
        setUI,
        cartUpdateTimeoutId,
        offerModalRef,
        offerState,
        setOfferState,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
