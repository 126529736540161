import React from "react";
import { useStoreContext } from "../../../../../context/StoreContext";

export default function SecondRow({ pricing }) {
  const {
    store: { user },
  } = useStoreContext();

  if (!user?._id || pricing.offerDiscount === 0) {
    return (
      <div className="flex gap-2 whitespace-nowrap text-xs items-center">
        <span className="flex  items-center font-[600] text-sm  text-[#000000] ">
          ₹{pricing?.sellingPrice - pricing.offerDiscount}
        </span>
        <span className="flex items-center font-[500]  text-[gray] line-through ">
          ₹{pricing?.costPrice}
        </span>
        <div
          style={{
            clipPath: "polygon(0 0, 100% 0%, 85% 100%, 0% 100%)",
          }}
          className=" text-center rounded-l pl-1.5 pr-3 py-0.5 text-2xs font-[600] bg-gradient-to-r from-blue-500  to-[#2136d4] text-[white] "
        >
          {pricing?.discountIncludingCoupon}% off
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-2 whitespace-nowrap text-xs items-center">
      <span className="flex  items-center font-[600] text-sm  text-[#000000] ">
        ₹{pricing.sellingPrice - pricing.offerDiscount}
      </span>
      <span className="flex items-center font-[500]  text-[gray] line-through ">
        ₹{pricing?.costPrice}
      </span>

      <div
        style={{
          clipPath: "polygon(0 0, 100% 0%, 85% 100%, 0% 100%)",
        }}
        className=" text-center rounded-l pl-1.5 pr-3 py-0.5 text-2xs font-[600] bg-gradient-to-r from-blue-500  to-[#2136d4] text-[white] "
      >
        {pricing?.discountIncludingCoupon}% off
      </div>
    </div>
  );
}
