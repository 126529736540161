import { useCallback, useEffect, useMemo, useState } from "react";
import { useStoreContext } from "../../context/StoreContext";
import eboUtils from "../../utils/eboUtils";
import { calc_least_price } from "../../functions/calculateLowesPrice";

// A hook that helps out to use a single product details
export default function useProduct({ product, isForCart = false }) {
  const {
    store: {
      selectedLocation: { serviceAreaInfo },
      cart,
    },
    offerState: { offers },
  } = useStoreContext();

  const hubId = useMemo(() => {
    return isForCart && cart?.hubId ? cart?.hubId : serviceAreaInfo?.hubId;
  }, [cart, isForCart, serviceAreaInfo?.hubId]);

  const [pricing, setPricing] = useState({
    isOnBooking: false,
    isCalculating: true,
    sellingPrice: "",
    costPrice: "",
    webDiscount: "",
    webDiscountPrice: "",
    fullPrepaidDiscount: "",
    offerDiscount: "",
    discountIncludingCoupon: "",
  });

  const setProductPricing = useCallback(() => {
    try {
      // getting the product's price detail for the selected location
      const productPriceDetail = product?.listingPriceHubWise?.find(
        (currHub) => currHub.hub === hubId
      );
      const isOnBooking = product?.tags?.includes("on-demand booking");

      const leastPriceRes = calc_least_price(
        productPriceDetail?.sellingPrice,
        offers
      );

      const fullPrepaidDiscount = eboUtils().calculate_prepaid_discount(
        productPriceDetail?.sellingPrice
      );
      const discountIncludingCoupon = Math.round(
        ((parseInt(productPriceDetail?.costPrice) -
          (parseInt(productPriceDetail?.sellingPrice) -
            parseInt(leastPriceRes?.discount || 0))) *
          100) /
          parseInt(productPriceDetail?.costPrice)
      );

      setPricing((p) => ({
        ...p,
        isOnBooking,
        isCalculating: false,
        sellingPrice: parseInt(productPriceDetail?.sellingPrice),
        costPrice: parseInt(productPriceDetail?.costPrice),
        webDiscount: parseInt(productPriceDetail?.webDiscount),
        webDiscountPrice:
          parseInt(productPriceDetail?.costPrice) -
          parseInt(productPriceDetail?.sellingPrice),
        fullPrepaidDiscount: fullPrepaidDiscount,
        offerDiscount: parseInt(leastPriceRes?.discount || 0),
        offerCode: leastPriceRes?.offerCode || "",
        discountIncludingCoupon,
      }));
    } catch (error) {
      console.error(error);
    }
  }, [hubId, product, offers]);

  useEffect(() => {
    setProductPricing();
  }, [setProductPricing]);

  return { pricing };
}
