import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import CompRenderingContext from "../../../context/CompRenderingContext";

const Profile = ({ user, logout }) => {
  const { setWarningModal } = useContext(CompRenderingContext);
  return (
    <div className="nav-p-dropdown-content absolute top-[2rem] p-[2rem] w-[20rem] ">
      <div className="flex bg-white p-4 w-full shadow border rounded-md flex-col">
        <div className=" font-[600] capitalize border-b-2 border-[#2136d4] pb-1 text-[1.2rem]">
          Hello, <span className=" text-[#2136d4]"> {user?.name}</span>
        </div>

        <div className=" flex gap-1 text-[.875rem] border-b pb-2 my-2 text-[black] flex-col">
          <Link className=" hover:font-[500]" to="/profile/mybookings">
            My Orders
          </Link>
          <Link className=" hover:font-[500]" to="/profile/myaccount">
            My Account
          </Link>
          <Link className=" hover:font-[500]" to="/profile/wishlist">
            My Wishlist
          </Link>
          <Link className=" hover:font-[500]" to="/profile/myaddress">
            My Addresses
          </Link>
        </div>

        <div
          className="cursor-pointer text-[.825rem] text-[grey]"
          onClick={() => {
            setWarningModal({
              text: "Do you want to logout?",
              isRender: true,
              funToBeExecuted: () => {},
              funToBeExecutedOnNo: () => {
                logout();
              },
              textInsteadOfYes: "No",
              textInsteadOfNo: "Yes",
            });
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
};

export default memo(Profile);
