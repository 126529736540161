import { useContext, useEffect } from "react";
import HomePageContext from "../context/HomepageContext";
import OfferContext from "../context/OfferContext";
import AuthContext from "../context/AuthContext";

import { useStoreContext } from "../context/StoreContext";

const useFetchEboData = () => {
  // const [queries] = useSearchParams();
  const { fetchHomeData, homeInitialData } = useContext(HomePageContext);
  const { user } = useContext(AuthContext);
  const {
    setUI,
    store: {
      selectedLocation: { serviceAreaInfo },
    },
  } = useStoreContext();
  const { offerState, getOffers } = useContext(OfferContext);

  useEffect(() => {
    callMethods();

    // eslint-disable-next-line
  }, [user?.id]);

  async function callMethods() {
    setUI((p) => ({
      ...p,
      home: {
        ...p.home,
        nav: {
          ...p.home.nav,
          backgroundThemeMode: "light",
          isDropBannerVisible: false,
        },
      },
    }));

    // if (queries.get("e")) {
    //   let bookingId = queries.get("e");
    //   // if the bookingId is not starting with e, then add it at the start
    //   if (!bookingId.startsWith("e") && !bookingId.startsWith("E")) {
    //     bookingId = "E" + bookingId;
    //   }
    //   const clientDomain = process.env.REACT_APP_CLIENT_URL;
    //   window.location.href = `${clientDomain}/e/${bookingId}`;
    // }

    await fetchHomeData();

    // make the dropBanner visible
    setTimeout(() => {
      setUI((p) => ({
        ...p,
        home: {
          ...p.home,
          nav: {
            ...p.home.nav,
            backgroundThemeMode: "dark",
            isDropBannerVisible: true,
          },
        },
      }));
    }, 1500);

    if (offerState.offers?.length === 0 || !offerState.offers) {
      await getOffers();
    }
  }

  useEffect(() => {
    async function callMethods() {
      homeInitialData?.initialData && (await fetchHomeData({}));
    }
    callMethods();
    // eslint-disable-next-line
  }, [serviceAreaInfo?.hubId]);

  return;
};

export default useFetchEboData;
