import React, { useContext } from "react";
import AuthContext from "../../../../../context/AuthContext";
import { FaLocationPinLock } from "react-icons/fa6";
import CompRenderingContext from "../../../../../context/CompRenderingContext";
import StructuredAddressContainer from "../../StructuredAddressContainer";
import useAddressManager from "../../../../../hooks/address/useAddressManager";

export default function SavedAddresses({ states, setStates }) {
  const { setLoginModal, setLocationData, setAddEditAddress } =
    useContext(CompRenderingContext);
  const { addresses } = useAddressManager();

  const {
    initialState: { user },
  } = useContext(AuthContext);

  if (states.isPredictionsShow) {
    return <></>;
  }

  if (user) {
    return (
      <div className="flex flex-col w-full ">
        {addresses?.length > 0 && (
          <div className=" uppercase mb-4 text-[#afafaf] font-[500] ">
            SAVED ADDRESSES
          </div>
        )}

        {addresses?.map((currAdd) => {
          return (
            <StructuredAddressContainer
              address={currAdd}
              key={currAdd._id}
              onClickCallback={() => {
                setLocationData((p) => ({
                  ...p,
                  isLocationAddModalRender: false,
                }));
              }}
              isSelecteAddress={true}
              hideCheckIcon={true}
              hideEditRemove={true}
            />
          );
        })}
        <div
          onClick={() => {
            setAddEditAddress((p) => ({
              ...p,
              isRender: true,
              address: null,
              type: "add",
            }));
            setLocationData((p) => ({
              ...p,
              isLocationAddModalRender: false,
            }));
            setStates((p) => ({
              ...p,
              defaultUi: false,
              showMap: true,
            }));
          }}
          className="mirror-Animation before:w-[20px] font-[500] rounded-lg py-2 mt-2 mb-4 gap-[2px] px-3 items-center 
        justify-center blue-gradient text-[1rem] flex"
        >
          Add New Address
        </div>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          setLocationData((p) => ({
            ...p,
            isLocationAddModalRender: false,
          }));

          setTimeout(() => {
            setLoginModal(true);
          }, 200);
        }}
        className="w-full flex blue-gradient  mirror-Animation before:w-[20px] justify-center gap-1  items-center text-[1rem] rounded-lg min-h-[3rem] h-[3rem] mt-4  font-[500]  "
      >
        <FaLocationPinLock className="text-[1.25rem]" />
        Login to view Saved addresses
      </div>
    );
  }
}
