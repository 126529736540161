import React from "react";
import { RiWhatsappFill } from "react-icons/ri";

import { TbPhoneCall } from "react-icons/tb";
import { Link, useSearchParams } from "react-router-dom";
import eboUtils from "../../utils/eboUtils";

export default function CustomizeDecorBanner({ screenWidth }) {
  const whatsappTextContent = "Hi, I need help to book!";

  const [searchParams, setSearchParams] = useSearchParams();

  const onCallBtnClick = () => {
    try {
      const params = new URLSearchParams(searchParams);
      params.set("ebo-ph", true);
      setSearchParams(params, { preventScrollReset: true });

      window.location.href = `tel:${eboUtils().callingNumber}`;
    } catch (error) {
      console.log(error);
    }
  };

  const onWhatsappBtnClick = () => {
    try {
      const params = new URLSearchParams(searchParams);
      params.set("ebo-wa", true);
      setSearchParams(params, { preventScrollReset: true });

      window.location.href = `https://wa.me/${
        eboUtils().whatsappNumber
      }?text=${whatsappTextContent}`;
    } catch (error) {
      console.log(error);
    }
  };

  if (screenWidth > 767) return <DesktopBanner />;
  else return <MobileBanner screenWidth={screenWidth} />;

  function DesktopBanner() {
    return (
      <div
        style={{
          background:
            "linear-gradient(104deg, #FFF 49.28%, #C6E0FB 81.79%, #258AF0 102.73%)",
        }}
        className=" p-2 flex  border w-full gap-4 rounded-xl h-[14rem] relative "
      >
        <div className=" flex flex-col w-[60%] justify-between">
          <p
            style={{
              backgroundImage:
                "linear-gradient(98deg, #2136D4 4.31%, #258AF0 56%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            className="text-[48px] font-bold  leading-[48px] mt-2"
          >
            Want a customised decor?
          </p>

          <div className=" flex justify-between mb-4">
            <div
              onClick={onCallBtnClick}
              className="flex gap-1 items-center justify-center  border rounded-[28px] p-2 w-[48%]"
            >
              <TbPhoneCall className="text-3xl" />
              <p className="whitespace-nowrap">Call and book</p>
            </div>
            <div
              onClick={onWhatsappBtnClick}
              target="_blank"
              className="flex gap-1 items-center justify-center  border rounded-[28px] p-2 bg-white w-[48%]"
            >
              <RiWhatsappFill className="text-[#25D366] text-3xl " />
              <p className="text-[#5B5B5B] whitespace-nowrap ">Chat with us</p>
            </div>
          </div>
        </div>
        <div className="    absolute right-0 -top-11 ">
          <img
            src="https://img.ebonow.com/Posters/customise.webp"
            alt="speak-to-us"
            className=" object-contain w-[320px] h-[320px]"
          />
        </div>
      </div>
    );
  }

  function MobileBanner({ screenWidth }) {
    const textSize =
      screenWidth < 350
        ? "text-lg leading-[21px]"
        : screenWidth < 370
        ? "text-[22px] leading-[23px]"
        : screenWidth < 430
        ? "text-2xl leading-[25px]"
        : screenWidth < 510
        ? "text-3xl leading-[31px]"
        : "text-4xl leading-[37px]";
    return (
      <div
        style={{
          background:
            "linear-gradient(104deg, #FFF 49.28%, #C6E0FB 81.79%, #258AF0 102.73%)",
        }}
        className="  p-4 flex  flex-col justify-between  w-full   rounded-xl relative h-[9rem] border "
      >
        <div className={` h-full float-right absolute right-[10px]  -top-4`}>
          <img
            src="https://img.ebonow.com/Posters/customise.webp"
            alt="speak-to-us"
            className=" object-fill  w-[150px] h-[150px]"
          />
        </div>
        <div className=" flex  ">
          <p
            style={{
              backgroundImage:
                "linear-gradient(98deg, #2136D4 4.31%, #258AF0 56%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            className={` ${textSize} font-bold w-[70%] `}
          >
            Want a customised decor?
          </p>
        </div>
        <div className=" flex justify-between ">
          <div
            onClick={onCallBtnClick}
            className="flex gap-1 items-center justify-center  border rounded-[28px] px-2 py-1 w-[48%]"
          >
            <TbPhoneCall className="text-xl" />
            <p className="whitespace-nowrap text-[0.875rem]">Call and book</p>
          </div>
          <Link
            onClick={onWhatsappBtnClick}
            target="_blank"
            className="flex gap-1 items-center justify-center  border rounded-[28px] px-2 py-1 bg-white w-[48%] z-[1]"
          >
            <RiWhatsappFill className="text-[#25D366] text-xl " />
            <p className="text-[#5B5B5B] whitespace-nowrap text-[0.875rem] ">
              Chat with us
            </p>
          </Link>
        </div>
      </div>
    );
  }
}
